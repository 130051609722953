import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

// LoginPage Component
const LoginPage = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginMessage, setLoginMessage] = useState('');

    const handleLogin = async () => {
        try {
            const response = await axios.post('/api/login', { username, password });
            const { token } = response.data;
            localStorage.setItem('token', token);
            onLogin(token);
        } catch (error) {
            setLoginMessage('Login fallito. Controlla username e password.');
        }
    };

    return (
        <div className="container-fluid d-flex justify-content-center align-items-center vh-100">
            <div className="card p-4 w-100" style={{ maxWidth: '400px' }}>
                <h2 className="text-center">Login</h2>
                <div className="form-group">
                    <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        className="form-control mb-3"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button className="btn btn-primary w-100" onClick={handleLogin}>Login</button>
                    {loginMessage && <div className="text-danger mt-3">{loginMessage}</div>}
                </div>
            </div>
        </div>
    );
};

// TablePage Component
const TablePage = ({ token, onLogout }) => {
    const [tables, setTables] = useState([]);
    const [selectedTable, setSelectedTable] = useState('');
    const [tableData, setTableData] = useState([]);
    const [tableMessage, setTableMessage] = useState('');
    const [editRow, setEditRow] = useState(null);
    const [editValues, setEditValues] = useState({});
    const [newRecord, setNewRecord] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchTables();
    }, [token]);

    const fetchTables = async () => {
        try {
            const response = await axios.get('/api/tables', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setTables(response.data);
            setTableMessage('Tabelle recuperate con successo.');
        } catch (error) {
            setTableMessage('Errore nel recupero delle tabelle.');
        }
    };

    const fetchTableData = async (tableName) => {
        try {
            const response = await axios.get(`/api/tables/${tableName}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setTableData(response.data);
            setSelectedTable(tableName);
        } catch (error) {
            setTableMessage('Errore nel recupero dei dati della tabella.');
        }
    };

    const handleEditValue = async (id) => {
        const confirmEdit = window.confirm("Sei sicuro di voler salvare le modifiche?");
        if (confirmEdit) {
            try {
                await axios.put(`/api/tables/${selectedTable}/${id}`, editValues, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setEditRow(null);
                fetchTableData(selectedTable);
            } catch (error) {
                console.error('Errore nell\'aggiornamento del valore:', error);
            }
        }
    };

    const handleValueChange = (key, value) => {
        setEditValues((prev) => ({
            ...prev,
            [key]: value
        }));
    };

    const handleToggleChange = (key, value) => {
        setEditValues((prev) => ({
            ...prev,
            [key]: value ? 'ON' : 'OFF'
        }));
    };

    const handleNewRecordChange = (key, value) => {
        setNewRecord((prev) => ({
            ...prev,
            [key]: value
        }));
    };

    const handleAddNewRecord = async () => {
        const confirmAdd = window.confirm("Sei sicuro di voler aggiungere questo record?");
        if (confirmAdd) {
            try {
                await axios.post(`/api/tables/${selectedTable}`, newRecord, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setNewRecord({});
                fetchTableData(selectedTable);
            } catch (error) {
                console.error('Errore nell\'aggiunta del nuovo record:', error);
            }
        }
    };

    const handleDeleteRecord = async (id) => {
        const confirmDelete = window.confirm("Sei sicuro di voler eliminare questo record?");
        if (confirmDelete) {
            try {
                await axios.delete(`/api/tables/${selectedTable}/${id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                fetchTableData(selectedTable);
            } catch (error) {
                console.error('Errore nell\'eliminazione del record:', error);
            }
        }
    };

    const handleInitializeGriglia = async () => {
        const confirmInitialize = window.confirm("Sei sicuro di voler azzerare tutti i record della tabella 'griglia'?");
        if (confirmInitialize) {
            try {
                // Iterate over all rows to set specified columns to 0
                const updatedRows = tableData.map(row => {
                    return {
                        ...row,
                        BUYED: 0,
                        BUYED_CRYPTO: 0,
                        BUYED_EURO: 0,
                        BUYED_AT: 0,
                        PTB: 0,
                        PTS: 0
                    };
                });

                // Update all rows in the database
                await Promise.all(updatedRows.map(row => 
                    axios.put(`/api/tables/griglia/${row.id}`, row, {
                        headers: { Authorization: `Bearer ${token}` }
                    })
                ));

                fetchTableData('griglia');
            } catch (error) {
                console.error('Errore nell\'inizializzazione della tabella:', error);
            }
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        onLogout();
        navigate('/'); // Redirects to the login page
    };

    return (
        <div className="container-fluid">
            <div className="row g-0">
                <div className="col-md-3 p-3">
                    <div className="card h-100">
                        <div className="card-body">
                            <h3>Tabelle</h3>
                            <button className="btn btn-info w-100 mb-3" onClick={() => navigate('/analytics')}>Analytics</button>
                            <ul className="list-group">
                                {tables.map((table) => (
                                    <li
                                        key={table}
                                        className={`list-group-item ${selectedTable === table ? 'active' : ''}`}
                                        onClick={() => fetchTableData(table)}
                                    >
                                        {table}
                                    </li>
                                ))}
                            </ul>
                            {tableMessage && <div className="mt-3 text-success">{tableMessage}</div>}
                        </div>
                    </div>
                </div>
                <div className="col-md-9 p-3">
                    <div className="d-flex justify-content-between mb-3">
                        <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
                        {selectedTable === 'griglia' && (
                            <button className="btn btn-warning" onClick={handleInitializeGriglia}>Initialize Griglia</button>
                        )}
                    </div>
                    {tableData.length > 0 && (
                        <div className="card h-100">
                            <div className="card-body">
                                <h3>Dati della tabella: {selectedTable}</h3>

                                <table className="table table-bordered table-responsive">
                                    <thead>
                                        <tr>
                                            {Object.keys(tableData[0]).map((key) => (
                                                <th key={key}>{key}</th>
                                            ))}
                                            <th>Azioni</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((row) => (
                                            <tr key={row.id}>
                                                {Object.keys(row).map((key) => (
                                                    <td key={key}>
                                                        {selectedTable === 'config' && key === 'VALUE' && row.KEY === 'STATO' && editRow === row.id ? (
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id={`toggle-${row.id}`}
                                                                    checked={editValues[key] === 'ON'}
                                                                    onChange={(e) => handleToggleChange(key, e.target.checked)}
                                                                />
                                                                <label className="form-check-label" htmlFor={`toggle-${row.id}`}>
                                                                    {editValues[key] === 'ON' ? 'ON' : 'OFF'}
                                                                </label>
                                                            </div>
                                                        ) : selectedTable === 'config' && key === 'VALUE' && row.KEY !== 'STATO' && editRow === row.id ? (
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={editValues[key] || row[key]}
                                                                onChange={(e) => handleValueChange(key, e.target.value)}
                                                            />
                                                        ) : (
                                                            selectedTable === 'griglia' && ['RIF_QUOTE', 'GAIN', 'PREZZO_QUOTA'].includes(key) && editRow === row.id ? (
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={editValues[key] || row[key]}
                                                                    onChange={(e) => handleValueChange(key, e.target.value)}
                                                                />
                                                            ) : (
                                                                row[key]
                                                            )
                                                        )}
                                                    </td>
                                                ))}
                                                <td>
                                                    {selectedTable === 'config' && (
                                                        <>
                                                            <button
                                                                className="btn btn-warning btn-sm"
                                                                onClick={() => {
                                                                    setEditRow(row.id);
                                                                    setEditValues(row);
                                                                }}
                                                            >
                                                                Modifica
                                                            </button>
                                                            {editRow === row.id && (
                                                                <button
                                                                    className="btn btn-success btn-sm ms-2"
                                                                    onClick={() => handleEditValue(row.id)}
                                                                >
                                                                    Salva
                                                                </button>
                                                            )}
                                                        </>
                                                    )}
                                                    {selectedTable === 'griglia' && (
                                                        <>
                                                            <button
                                                                className="btn btn-warning btn-sm"
                                                                onClick={() => {
                                                                    setEditRow(row.id);
                                                                    setEditValues(row);
                                                                }}
                                                            >
                                                                Modifica
                                                            </button>
                                                            {editRow === row.id && (
                                                                <button
                                                                    className="btn btn-success btn-sm ms-2"
                                                                    onClick={() => handleEditValue(row.id)}
                                                                >
                                                                    Salva
                                                                </button>
                                                            )}
                                                            <button
                                                                className="btn btn-danger btn-sm ms-2"
                                                                onClick={() => handleDeleteRecord(row.id)}
                                                            >
                                                                Elimina
                                                            </button>
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        {selectedTable === 'griglia' && (
                                            <tr>
                                                {Object.keys(tableData[0]).map((key) => (
                                                    <td key={key}>
                                                        {key === 'RIF_QUOTE' || key === 'GAIN' || key === 'PREZZO_QUOTA' ? (
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={newRecord[key] || ''}
                                                                onChange={(e) => handleNewRecordChange(key, e.target.value)}
                                                            />
                                                        ) : null}
                                                    </td>
                                                ))}
                                                <td>
                                                    <button
                                                        className="btn btn-primary btn-sm"
                                                        onClick={handleAddNewRecord}
                                                    >
                                                        Aggiungi
                                                    </button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

// AnalyticsPage Component
const AnalyticsPage = ({ token }) => {
    const [statistics, setStatistics] = useState([]);
    const [dailyStats, setDailyStats] = useState({});
    const [monthlyStats, setMonthlyStats] = useState({});
    const [totalStats, setTotalStats] = useState({});
    const [dailySummary, setDailySummary] = useState({});

    useEffect(() => {
        fetchStatistics();
    }, [token]);

    const fetchStatistics = async () => {
        try {
            const response = await axios.get('/api/tables/statistics', {
                headers: { Authorization: `Bearer ${token}` }
            });
            const stats = response.data;
            setStatistics(stats);
            calculateStats(stats);
        } catch (error) {
            console.error('Errore nel recupero delle statistiche:', error);
        }
    };

    const calculateStats = (stats) => {
        const daily = {
            profit: 0,
        };
        const monthly = {
            profit: 0,
        };
        const total = {
            profit: 0,
        };
        const dailySummary = {};

        const today = new Date();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();

        stats.forEach((stat) => {
            const date = new Date(stat.timestamp);
            const dateString = date.toISOString().split('T')[0];

            // Calculate daily stats
            if (date.toDateString() === today.toDateString()) {
                daily.profit += stat.profit;
            }

            // Calculate monthly stats
            if (date.getMonth() === currentMonth && date.getFullYear() === currentYear) {
                monthly.profit += stat.profit;
            }

            // Calculate total stats
            total.profit += stat.profit;

            // Daily summary
            if (!dailySummary[dateString]) {
                dailySummary[dateString] = {
                    profit: 0,
                };
            }
            dailySummary[dateString].profit += stat.profit;
        });

        setDailyStats({ profit: daily.profit.toFixed(2) });
        setMonthlyStats({ profit: monthly.profit.toFixed(2) });
        setTotalStats({ profit: total.profit.toFixed(2) });
        setDailySummary(Object.fromEntries(
            Object.entries(dailySummary).map(([date, summary]) => [date, { profit: summary.profit.toFixed(2) }])
        ));
    };

    return (
        <div className="container-fluid">
            <h2>Statistiche Giornaliere</h2>
            <div className="card p-4">
                <h4>Oggi</h4>
                <p>Profitto: {dailyStats.profit}€</p>
            </div>

            <h2 className="mt-4">Statistiche Mensili</h2>
            <div className="card p-4">
                <h4>Mese Corrente</h4>
                <p>Profitto: {monthlyStats.profit}€</p>
            </div>

            <h2 className="mt-4">Statistiche Totali</h2>
            <div className="card p-4">
                <h4>Tutti i Tempi</h4>
                <p>Profitto Totale: {totalStats.profit}€</p>
            </div>

            <h2 className="mt-4">Riepilogo Giornaliero</h2>
            <div className="card p-4">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Profitto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(dailySummary).map(date => (
                            <tr key={date}>
                                <td>{date}</td>
                                <td>{dailySummary[date].profit}€</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

// Main App Component
const App = () => {
    const [token, setToken] = useState(localStorage.getItem('token'));

    const handleLogin = (newToken) => {
        setToken(newToken);
    };

    const handleLogout = () => {
        setToken(null);
        localStorage.removeItem('token');
    };

    return (
        <Router>
            <Routes>
                <Route path="/" element={token ? <TablePage token={token} onLogout={handleLogout} /> : <LoginPage onLogin={handleLogin} />} />
                <Route path="/analytics" element={<AnalyticsPage token={token} />} />
            </Routes>
        </Router>
    );
};

export default App;

